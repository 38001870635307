/*!
 * Bootstrap v3.3.7
 */

// Core variables and mixins
@import 'bootstrap/variables';
@import 'bootstrap/mixins';

// Reset and dependencies
@import 'bootstrap/normalize';
@import 'bootstrap/print';
@import 'bootstrap/glyphicons';

// Core CSS
@import 'bootstrap/scaffolding';
@import 'bootstrap/type';
@import 'bootstrap/code';
@import 'bootstrap/grid';
@import 'bootstrap/tables';
@import 'bootstrap/forms';
@import 'bootstrap/buttons';

// Components
@import 'bootstrap/component-animations';
@import 'bootstrap/dropdowns';
@import 'bootstrap/button-groups';
@import 'bootstrap/input-groups';
@import 'bootstrap/navs';
@import 'bootstrap/navbar';
@import 'bootstrap/breadcrumbs';
@import 'bootstrap/pagination';
@import 'bootstrap/pager';
@import 'bootstrap/labels';
@import 'bootstrap/badges';
@import 'bootstrap/jumbotron';
@import 'bootstrap/thumbnails';
@import 'bootstrap/alerts';
@import 'bootstrap/progress-bars';
@import 'bootstrap/media';
@import 'bootstrap/list-group';
@import 'bootstrap/panels';
@import 'bootstrap/responsive-embed';
@import 'bootstrap/wells';
@import 'bootstrap/close';

// Components w/ JavaScript
@import 'bootstrap/modals';
@import 'bootstrap/tooltip';
@import 'bootstrap/popovers';
@import 'bootstrap/carousel';

// Utility classes
@import 'bootstrap/utilities';
@import 'bootstrap/responsive-utilities';
@import './spacings';

// Font Section //
$fa-font-path: './fonts/fontawesome';
@import 'fontawesome/font-awesome';

// main styles //
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

body {
  background: white;
  color: #333;
  font-family: sans-serif;
  font-weight: 400;
  height: 100%;
  line-height: 1.6em;
}

#root,
#root > div {
  height: 100%;
}

.wrapper {
  min-height: 100%;
  padding-bottom: 100px;
  position: relative;
}

// footer //
.footer {
  background: #f5f5f5 !important;
  bottom: 0;
  display: block;
  height: 70px;
  left: 0;
  margin: 0;
  padding: 20px 0;
  position: fixed;
  width: 100%;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  a {
    border-bottom: 1px solid transparent;
    color: #858585;
    display: inline-block;
    font-weight: 400;
    padding: 2px 10px;

    &:hover {
      color: $brand-blue;
      text-decoration: none;
    }
  }
}

.alerts-container {
  bottom: 90px;
  display: block;
  height: 70px;
  left: 0;
  margin: 0;
  padding: 20px 0;
  position: fixed;
  width: 100%;
}

// alerts //
.alert {
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 10px 15px;
}

.alert-danger {
  background: rgba(197, 70, 63, 0.15);
  border: 0 solid #ff0000;
  border-left: 10px solid #ff0000;
  color: #ff0000;
}

.spinner {
  animation: circle infinite 1.5s linear;
  border: 4px solid transparent;
  border-bottom-color: lighten(#3b3b3b, 10%);
  border-top-color: lighten(#3b3b3b, 30%);
  border-radius: 100%;
  border-right-color: lighten(#3b3b3b, 20%);
  height: 25px;
  margin: 0 auto;
  position: relative;
  width: 25px;

  &.big {
    height: 50px;
    margin: 45px auto;
    width: 50px;
  }
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

// tables //
table thead td {
  font-weight: bold;
}

table tbody td {
  .radio {
    margin-bottom: 0;
    margin-top: 0;

    input[type='radio'] {
      margin-left: -16px;
    }
  }

  &.money {
    text-align: right;
  }
}

// text styling //
a {
  transition: $fade-transition;

  &:hover {
    text-decoration: none;
  }
}

.text-align-right {
  text-align: right;
}

.red-frame {
  border-color: red;
  border-style: solid;
  color: red;
  padding: 10px 15px;
}

.leasing-application,
.salesman {
  border: 1px solid #ddd;
  margin: 5px 0;
  padding: 10px;
}

// text info //
.info-text {
  strong {
    display: inline-block;
    padding-bottom: 5px;
  }
  padding-bottom: 15px;
}

// dashboard thumbnails //
.caption {
  word-wrap: break-word;
}

.thumbnail {
  margin-top: 10px;
  min-height: 30px;
  position: relative;

  span {
    color: $brand-blue;
    display: inline;
    padding-right: 8px;
  }

  .thumbnail-img-container {
    max-height: 400px;
    overflow: hidden;
    width: 100%;

    img {
      height: auto;
      width: 100%;
    }
  }
}

.asterisk-required {
  margin-left: 0.2em;
  color: #a94442;
}

.hint-required {
  text-align: right;
  color: #666;
  font-style: italic;
}

.addresses {
  margin-bottom: 2em;
}

// navigation //
.navbar.navbar-default {
  background-color: $brand-blue;
  border: 0;
  border-radius: 0;

  li > a {
    color: #fff;
    transition: 0.2s ease-in-out;

    &:active,
    &:hover {
      background-color: $brand-blue;
      color: $brand-yellow;
    }
  }
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: $brand-blue;
  color: $brand-yellow;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  border-color: $brand-yellow;
  color: $brand-yellow;
}

li.sign-in-out-btn {
  align-items: center;
  display: flex;
  min-height: 50px;
  padding: 0 15px;

  a {
    border: 1px solid #fff;
    border-radius: 100px;
    padding-bottom: 5px;
    padding-top: 5px;

    &:active,
    &:hover {
      color: $brand-yellow;
      background-color: transparent !important;
      border-color: $brand-yellow;
    }
  }

  a:hover {
    border: 1px solid $brand-yellow;
  }
}

.navbar-header > .navbar-brand {
  color: $white;
  font-size: auto;

  &:focus {
    color: $white;
  }

  &:active,
  &:hover {
    color: darken($white, 30%);
  }
}

// buttons //
button {
  transition: $fade-transition;
}

@media (min-width: $screen-sm-min) {
  .label-value-btn-position {
    float: right;
  }
}

.icon-left {
  padding-right: 6px;
}

.icon-right {
  padding-left: 6px;
}

.btn-default.active,
.btn-default.active:hover,
.btn-default:hover {
  background-color: $brand-blue;
  color: #fff;
}

.table-striped > tbody > a:nth-of-type(odd) {
  background-color: #f9f9f9;

  &:hover {
    background-color: #ededed;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    text-decoration: none;
  }
}

.list-table {
  height: 198px;
  overflow-y: auto !important;
}

.list-table-row:hover {
  background-color: #efefef !important;
}

.list-table-cell {
  outline: none;
  color: #333333;
  cursor: pointer;
  white-space: nowrap;
}

.list-table-cell:hover {
  outline: none;
  color: #333333;
}

.list-table-cell:focus {
  outline: none;
  color: #333333;
  text-decoration: none;
}

.list-table-cell-link {
  color: #333333;
}

.list-table-cell-link:hover {
  color: #333333;
}

.list-table-cell-link:focus {
  outline: none;
  text-decoration: none;
  color: #333333;
}

.list-table-cell-link-inactive {
  color: #999999;
}

.list-table-cell-link-inactive:hover {
  color: #999999;
}

.list-table-cell-link-inactive:focus {
  outline: none;
  text-decoration: none;
  color: #999999;
}

.list-table-active-element {
  background-color: #1561ac !important;
  color: white !important;
}

// label value pages //
@media (max-width: $screen-xs-max) {
  .label-value-wrapper {
    margin-bottom: 5px;
  }
}

iframe.document {
  border: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.nav-tabs {
  margin-bottom: 15px;
}

.empty-row {
  margin-bottom: 10px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

@media (min-width: 768px) {
  .thumbnail {
    min-height: 330px;
  }
}
