$spacer: 16px;

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pt-1 {
  padding-top: $spacer * 0.25 !important;
}

.pb-1 {
  padding-bottom: $spacer * 0.25 !important;
}

.pl-1 {
  padding-left: $spacer * 0.25 !important;
}

.pr-1 {
  padding-right: $spacer * 0.25 !important;
}

.pt-2 {
  padding-top: $spacer * 0.5 !important;
}

.pb-2 {
  padding-bottom: $spacer * 0.5 !important;
}

.pl-2 {
  padding-left: $spacer * 0.5 !important;
}

.pr-2 {
  padding-right: $spacer * 0.5 !important;
}

.pt-3 {
  padding-top: $spacer !important;
}

.pb-3 {
  padding-bottom: $spacer !important;
}

.pl-3 {
  padding-left: $spacer !important;
}

.pr-3 {
  padding-right: $spacer !important;
}

.pt-4 {
  padding-top: $spacer * 1.5 !important;
}

.pb-4 {
  padding-bottom: $spacer * 1.5 !important;
}

.pl-4 {
  padding-left: $spacer * 1.5 !important;
}

.pr-4 {
  padding-right: $spacer * 1.5 !important;
}

.pt-5 {
  padding-top: $spacer * 3 !important;
}

.pb-5 {
  padding-bottom: $spacer * 3 !important;
}

.pl-5 {
  padding-left: $spacer * 3 !important;
}

.pr-5 {
  padding-right: $spacer * 3 !important;
}

.pt-auto {
  padding-top: auto !important;
}

.pb-auto {
  padding-bottom: auto !important;
}

.pl-auto {
  padding-left: auto !important;
}

.pr-auto {
  padding-right: auto !important;
}

.px-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.px-1 {
  padding-right: $spacer * 0.25 !important;
  padding-left: $spacer * 0.25 !important;
}

.px-2 {
  padding-right: $spacer * 0.5 !important;
  padding-left: $spacer * 0.5 !important;
}

.px-3 {
  padding-right: $spacer !important;
  padding-left: $spacer !important;
}

.px-4 {
  padding-right: $spacer * 1.5 !important;
  padding-left: $spacer * 1.5 !important;
}

.px-5 {
  padding-right: $spacer * 3 !important;
  padding-left: $spacer * 3 !important;
}

.px-auto {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mt-1 {
  margin-top: $spacer * 0.25 !important;
}

.mb-1 {
  margin-bottom: $spacer * 0.25 !important;
}

.ml-1 {
  margin-left: $spacer * 0.25 !important;
}

.mr-1 {
  margin-right: $spacer * 0.25 !important;
}

.mt-2 {
  margin-top: $spacer * 0.5 !important;
}

.mb-2 {
  margin-bottom: $spacer * 0.5 !important;
}

.ml-2 {
  margin-left: $spacer * 0.5 !important;
}

.mr-2 {
  margin-right: $spacer * 0.5 !important;
}

.mt-3 {
  margin-top: $spacer !important;
}

.mb-3 {
  margin-bottom: $spacer !important;
}

.ml-3 {
  margin-left: $spacer !important;
}

.mr-3 {
  margin-right: $spacer !important;
}

.mt-4 {
  margin-top: $spacer * 1.5 !important;
}

.mb-4 {
  margin-bottom: $spacer * 1.5 !important;
}

.ml-4 {
  margin-left: $spacer * 1.5 !important;
}

.mr-4 {
  margin-right: $spacer * 1.5 !important;
}

.mt-5 {
  margin-top: $spacer * 3 !important;
}

.mb-5 {
  margin-bottom: $spacer * 3 !important;
}

.ml-5 {
  margin-left: $spacer * 3 !important;
}

.mr-5 {
  margin-right: $spacer * 3 !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.px-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.mx-1 {
  margin-right: $spacer * 0.25 !important;
  margin-left: $spacer * 0.25 !important;
}

.mx-2 {
  margin-right: $spacer * 0.5 !important;
  margin-left: $spacer * 0.5 !important;
}

.mx-3 {
  margin-right: $spacer !important;
  margin-left: $spacer !important;
}

.mx-4 {
  margin-right: $spacer * 1.5 !important;
  margin-left: $spacer * 1.5 !important;
}

.mx-5 {
  margin-right: $spacer * 3 !important;
  margin-left: $spacer * 3 !important;
}

.mx-auto {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
